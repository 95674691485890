import { datadogRum } from '@datadog/browser-rum';

export const initialiseRUM = (): void => {
  const datadogEnv = import.meta.env.VITE_DD_ENV;
  const isDevelopment = !import.meta.env.PROD;
  const isDebugEnv = datadogEnv === 'debug';

  if (isDevelopment || isDebugEnv) {
    return;
  }

  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APP_ID,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'event-dashboard',
    env: datadogEnv,
    sampleRate: 100,
    replaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
};
